import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/views/home/home.component';
import { UnderConstructionComponent } from './components/views/under-construction/under-construction.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PrivacyPolicyComponent } from './components/views/privacy-policy/privacy-policy.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { NgsRevealModule } from 'ngx-scrollreveal';
import { ClientsComponent } from './components/shared/clients/clients.component';
import { ServiceCardsComponent } from './components/shared/service-cards/service-cards.component';
import { HeroComponent } from './components/shared/hero/hero.component';
import { PricingComponent } from './components/shared/pricing/pricing.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { FeaturesExtendedComponent } from './components/shared/features-extended/features-extended.component';
import { FeaturesCardsComponent } from './components/shared/features-cards/features-cards.component';
import { SocialIconsComponent } from './components/shared/social-icons/social-icons.component';
import { MenuLinksComponent } from './components/shared/menu-links/menu-links.component';
import { FloatingSquaresComponent } from './components/shared/floating-squares/floating-squares.component';
import { NotFoundComponent } from './components/views/not-found/not-found.component';
import { ContactComponent } from './components/shared/contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UnderConstructionComponent,
    PrivacyPolicyComponent,
    HeaderComponent,
    ClientsComponent,
    ServiceCardsComponent,
    HeroComponent,
    PricingComponent,
    FooterComponent,
    FeaturesExtendedComponent,
    FeaturesCardsComponent,
    SocialIconsComponent,
    MenuLinksComponent,
    FloatingSquaresComponent,
    NotFoundComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    NgsRevealModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
