<section class="hero text-light text-center" ngsReveal>
  <div class="container-sm">
    <div class="hero-inner">
      <h1 class="hero-title h2-mobile mt-0 is-revealing" ngsReveal>Somos la nueva generacion de tecnologia</h1>
      <p class="hero-paragraph is-revealing" ngsReveal>Llevamos la tecnologia a las palmas de tu mano de la manera mas facil posible y con todo el profesionalismo que tu empresa se merece.</p>

      <!--
      <div class="hero-media" ngsReveal>
        <video width="100%" mute autoplay preload>
          <source src="./assets/video/video.mp4" type="video/mp4">
            <img src="./assets/img/code1.jpg" alt="Video no soportado">
        </video>
      </div>
      -->
    </div>
  </div>
</section>
