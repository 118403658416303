<ul [class]="class">
  <li>
    <a href="./">Inicio</a>
  </li>
  <li>
    <a href="#services">Servicios</a>
  </li>
  <li>
    <a href="#about">Sobre nosotros</a>
  </li>
  <!--
  <li>
    <a href="#contact">Contactanos</a>
  </li>
  -->
</ul>
