import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  public appName: string;
  public type: string;

  public types = ['android', 'web'];
  public apps = ['China TV', ' Nicaragua TV', 'Wash Your Hands', 'EseMenu', 'Orvis Costa Rica', 'SCRUM Guide Handbook'];

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe((params: { app: any; type: any; }) => {
      if (this.types.includes(params.type) && this.apps.includes(params.app)) {
        this.appName = params.app;
        this.type = params.type;
      } else {
        this.router.navigate(['/']);
      }
    });
  }



}
